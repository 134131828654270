.HowWeHelp{
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
  line-height: 30.4px;
  color: grey;
  margin-top: 10%;

  .howWeHelpTitle {
    margin-left: 13%;
  }

  .howWeHelpDescription {
    width: 75%;
    margin-left: 13%;
    margin-top: 55px;
    margin-bottom: 5.5rem;
    font-size: 18px;
    line-height: 160%;
    background-color: rgba($color: #000, $alpha: 0.23);
    border-radius: 8px;

    &.mobile {
      display: none;
    }

    &.desktop {
      display: block;
    }

    .slide {
      text-align: start;
    }

    .subTitle {
      display: block;
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: white;
    }

    .description {
      padding: 10px 0 20px;
    }
  }
}

@import "./HowWeHelpMediaQueries.scss";
