@import "../../../scss/global.scss";

@media only screen and (max-width: $Tablet-Desktop) {
  .partnersContainer {
    width: 80%;

    .partnersTitle {
      position: relative;
      margin-left: 15%;
    }

    .hexagonsRelativeDiv {
      display: none;
    }

    .hexagonsRelativeDivMobile {
      margin-left: 20%;
      margin-right: auto;
    }
  }

  @media only screen and (max-width: $Mobile) {
    .partnersContainer {
      width: 100%;

      .hexagonsRelativeDivMobile {
        margin-left: auto;
        margin-right: auto;
      }

      .partnersTitle {
        position: relative;
        margin-left: 0%;
      }
    }
  }

  .hexagonsRow1Mobile {
    margin-top: 7%;
    display: flex;
  }
  
  .hexagonsRow2Mobile {
    margin-top: -8%;
    display: flex;
  }

  .hexagonsRow3Mobile {
    margin-top: -8%;
    display: flex;
  }
}

@media only screen and (min-width: $Tablet-Desktop) {
  .partnersContainer {
    .hexagonsRelativeDiv {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .hexagonsRelativeDivMobile {
    margin-left: 0%;
    display: none;
  }

  .overlayPartners {
    display: none;
  }
}

@media only screen and(min-width : $Desktop) {
  .partnersContainer {
    .partnersTitle {
      position: absolute;
      top: 0%;
    }

    .hexagonsRelativeDiv {
      display: block;
    }

    .hexagonsRelativeDivMobile {
      display: none;
    }

    .hexagonContainer {
      .popupRelativeDiv {
        display: block;
      }

      .overlayPartners {
        display: none;
      }
    }
  }
}
