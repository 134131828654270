@import "../../../scss/global.scss";


@mixin logoFadePosition {
  left: 44.86%;
  right: 44.86%;
  top: 39.89%;
  bottom: 41.89%;
}

@mixin logoAfterFadePosition {
  left: 23.83%;
  right: 44.86%;
  top: 39.89%;
  bottom: 41.89%;
}


@mixin logoFadePositionMobile {
  left: 80.86%;
  right: 44.86%;
  top: 39.89%;
  bottom: 41.89%;
}
