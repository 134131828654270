@import "../../../../../scss/global.scss";

.popUpTrigger {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: $popUpTrigger;
}

.hexagonContainer {
    position: relative;
    

    .logoRelativeDiv {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      height: 60%;
      width: 60%;
      color: white;

      &.notClickable {
        cursor: default;
      }

      &.empty {
        font-size: 1vw;
        font-style: italic;
        text-align: center;
      }
    }

  
    .logoRelativeDivAnimated {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      height: 60%;
      width: 60%;
      animation: hexagonFadeOut 0.4s;
      animation-fill-mode: forwards;
     
    }
  
    .popupRelativeDiv {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 30%;
      right: 20%;
      cursor: pointer;
      animation: popUpFadeIn 0.7s;
      z-index: 30;

      
    }
  
    .popup {
      background-image: url("../../../../../assets/hexagons/PolygonWhite.png");
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      top: 0%;
      height: 100vh;
      
      width: 25vw;
      z-index: 30;

      .popUpElements {
        overflow-y: hidden;
        align-items: center;
        font-family: Poppins;
        display: flex;
        flex-direction: column;
        font-weight: 400;
        text-align: center;
      }
    }
  }
  
  .hexagonShape {
    height: 100%;
    width: 100%;
    cursor: pointer;
    position: relative;

    &.notClickable {
      cursor: default;
    }
  }
  
  .hexagonShapeAnimated {
    height: 100%;
    width: 100%;
    cursor: pointer;
    position: relative;
    animation: hexagonFadeOut 0.4s;
    animation-fill-mode: forwards;
  }

  @import "./HexagonMediaQueries.scss";
