@import "../../../scss/global.scss";

@media only screen and(min-width : $Tablet) {

  .formSpiderMobile {
    display: none;
  }
}

@media only screen and (max-width: $Tablet) {
  
  .ContactUs {
    margin-top: 2%;
    flex-direction: column;

    #formContainer {
      margin-top: 5%;
      width: 80%;
      left: 12.5%;
    }

    .formSpiderDesktop {
      display: none;
    }
  }
}

@media only screen and (max-width: $Mobile) {

  .aboutUsTitle {
    margin-left: 0%;
  }

  .ContactUs {


    #formContainer {
      left: 30px;
    }

    .contactUsTitle {

      margin-left: 0%;
    }

    .socialInfo {
      margin-left: 30px;
    }

    .socialIcons {
      margin-left: 30px;
    }
  }
}

@media only screen and (min-width: $XXLDesktop) {
  .ContactUs {

    .socialInfo {
      font-size: 22px;
    }

    #formContainer {

      input[type="text"],
      [type="lname"],
      [type="email"],
      [type="subject"] {
        font-size: 22px;
      }

      textarea {
        font-size: 22px;
      }
    }

    .characterCount {
      font-size: 22px;
    }
  }

  .submit {

    .text {
      font-size: 22px;
    }

    .icon {
      width: 40px;
      height: 20px;
    }
  }
}
@media only screen and (min-width: $UHD) {
  .ContactUs {

    .socialInfo {
      font-size: 30px;
    }
    #formContainer {
      input[type="text"],
      [type="lname"],
      [type="email"],
      [type="subject"] {
        font-size: 28px;
      }
      textarea {
        font-size: 28px;
      }
    }

    .characterCount {
      font-size: 28px;
    }
  }

  .submit {

    .text {
      font-size: 28px;
    }

    .icon {
      width: 40px;
      height: 20px;
    }
  }
}
