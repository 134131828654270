//Variables

$Mobile : 768px;
$Tablet : 1024px;
$Tablet-Desktop: 1100px; // media query between desktop and tablet, used for the hexagons layout
$Desktop: 1200px;
$LargeDesktop: 1600px;
$ExtraLargeDesktop: 2000px;
$XXLDesktop : 2300px;
$UHD : 3000px;


// zIndex

$overlayContainer : 10; // should be lower than WhatWeDo(spiderDesk)
$spiderDesk: 20; // should be higher than Overlay
$NavBar : 30; // should be higher than SpiderDesk but lower than Overlay, dinamically modified through the dom
$overlayContainerMobile: 40; // should always be higher than the rest, doesn't have interactions with other components
$popUpTrigger: 50;
$planetContainer : -10; //for the rotating circles on the homePage, the circles should be behind the text.

//keyframes

@keyframes overlayfadein {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slideFromAbove {
    0% {
      transform: translateY(-200%);
    }
    80% {
      transform: translateX(0%);
    }
  }


  @keyframes slideFromRight {
    0% {
      transform: translateX(200%);
    }
    80% {
      transform: translateX(0%);
    }
  }

  
  @keyframes hexagonFadeOut {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }


  @keyframes popUpFadeIn {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  #HomePage {
    overflow-x: hidden;
  }


  //mixins

  @mixin placeBehindMap {
    position: absolute;
    z-index: -1;
  }
