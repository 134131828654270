@import "../../../../../scss/global.scss";

@media only screen and (max-width: $Mobile) {

  .hexagonContainer { 

    .logoRelativeDiv{
      &.empty {
        font-size: 2vw;
      }
    }
  }
 }


@media only screen and (max-width: $Tablet-Desktop) {
  .hexagonContainer {

    .popupRelativeDiv {
      display: none;
    }
  }

  .overlayPartners {
    display: block;
  }
}

@media only screen and (min-width: $Tablet-Desktop) {
  .hexagonContainer {

    .popUpDescriptionContainer {
      position: absolute;
      left: 43%;
      bottom: 38%;
      transform: translate(-45%, 13%);
      width: 65%;
      height: 70%;

      .popUpElements {
        width: 100%;
        height: 100%; 
        font-size: 10px;
        line-height: 22.4px;
       
        .popUpDescription {
          width: 80%;
          margin-top: 7%;
          margin-left: 6%;
          white-space: pre-wrap;
          overflow-y: scroll;
          height: 120px !important;

          &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 4px;
            height: 4px;
          }
          
          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, .6);
            box-shadow: 0 0 1px rgba(255, 255, 255, .5);
          }

        }

        .xIconPopUp {
          margin-left: 13%;
          margin-bottom: 3%;
          width: 10%;
          margin-top: 5%;
        }

        .logoPopUp {
          margin-top: 10%;
          margin-left: 7%;
        }
      }
    }
  }
}

@media only screen and(min-width : $Desktop) {
  .hexagonContainer {

    .popUpDescriptionContainer {
      position: absolute;
      left: 43%;
      bottom: 38%;
      transform: translate(-45%, 13%);
      width: 65%;
      height: 70%;

      .popUpElements {
        font-size: 11px;
        line-height: 22.4px;

        .popUpDescription {
          padding: 1px 7px 1px 7px;
          margin-top: 5%;
          margin-left: 5%;
          overflow-y: scroll;
          height: 130px !important;   
       
        }

        .xIconPopUp {
          width: 8%;
          margin-top: 5%;
          margin-left: 13%;
          margin-bottom: 5%;
        }

        .logoPopUp {
          margin-top: 10%;
        }
      }
    }
  }
}

@media only screen and(min-width : $LargeDesktop) {
  .popUpDescriptionContainer {
    left: 43%;
    bottom: 35%;
    transform: translate(-45%, 13%);
    width: 65%;
    height: 70%;

    .popUpElements {
      font-size: 16px;

      .popUpDescription {
        margin-top: 7%;
        margin-left: 5%;
        overflow-y: scroll;
        height: 100% !important;
      }

      .xIconPopUp {
        width: 10%;
        margin-left: 12.1%;
        margin-bottom: 5%;
        margin-top: 0%;
      }

      .logoPopUp {
        margin-top: 10%;
      }
    }
  }
}

@media only screen and(min-width : $ExtraLargeDesktop) {
  .hexagonContainer {

    .popUpDescriptionContainer {
      left: 45%;
      bottom: 35%;

      .popUpElements {
        height: 35%;
        font-size: 18px;

        .popUpDescription {
          width:100% !important;
          height: 100% !important;
          margin-left: 3%;
          margin-top: 4%;
        }

        .xIconPopUp {
          width: 10%;
          margin-left: 0%;
          margin-bottom: 5%;
        }
      }
    }
  }
}

@media only screen and(min-width : $XXLDesktop) {
  .hexagonContainer {

    .popUpDescriptionContainer {
      left: 55%;
      bottom: 10%;
      height: 100%;

      .popUpElements {
        width: 70%;
        font-size: 20px;
        line-height: 28.4px;

        .popUpDescription {
          height: 100% !important;
          margin-left: 3%;
          margin-top: 3%;
        }

        .xIconPopUp {
          width: 10%;
          margin-left: 10%;
          margin-bottom: 5%;
        }
      }
    }
  }
}

@media only screen and(min-width : $UHD) {
  .hexagonContainer {
    
    .popUpDescriptionContainer {

      .popUpElements {
        width: 70%;
        font-size: 26px;
        line-height: 35.4px;

        .popUpDescription {
          height: 100% !important;
          margin-left: 3%;       
        }

        .xIconPopUp {
          width: 10%;
          margin-left: 10%;
          margin-bottom: 5%;
        }
      }
    }
  }
}

