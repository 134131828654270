.ContactUs {
  width: 100%;
  height: 100%;
  margin-top: 2%;

  .contactUsTitle {
    margin-left: 13.0%;
  }

  #formContainer {
    border: none;
    width: 40%;
    right: 5%;
    position: relative;
    text-align: left;
    font-family: Poppins;
    font-size: 16px;
    line-height: 24px;

    .submit {
      .text {
        vertical-align: "middle";
        display: inline-block;
        margin-right: 10px;
      }

      .icon {
        vertical-align: middle;
      }
    }

    .lname {
      float: right;
      width: 50%;
    }

    .email {
      width: 100%;
      float: right;
    }

    .message {
      float: none;
      width: 100%;
    }

    .name {
      float: left;
      width: 45%;
    }

    .subject {
      width: 100%;
    }

    textarea {
      line-height: 150%;
      height: 300px;
      resize: none;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      border: none;
      box-sizing: border-box;
      padding: 20px;
      border-radius: 8px;
      color: #474544;
      font-size: 15px;
      font-weight: 400;
    }

    #form_button {
      background: none;
      background-color: rgba(102, 45, 144, 1);
      border-radius: 8px;
      border: solid 2px #122293;
      color: white;
      cursor: pointer;
      display: inline-block;
      font-size: 0.875em;
      font-weight: bold;
      outline: none;
      margin-top: 20px;
      width: 100%;
      padding: 14px 28px;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
    }

    button,
    input,
    select,
    textarea {
      color: #5a5a5a;
      font: inherit;
      margin: 0;
    }

    input {
      line-height: normal;
    }

    input[type="text"],
    [type="lname"],
    [type="email"],
    [type="subject"] {
      background-color: rgba(0, 0, 0, 0.3);
      border: none;
      border-radius: 8px;
      color: #474544;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 1px;
      margin: 0em 0 15px 0;
      height: 50px;
      padding: 20px;
      color: grey;
      text-transform: capitalize;
      vertical-align: middle;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      -ms-box-sizing: border-box;
      -o-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
    }  

    input[type="text"]:focus,
    [type="email"]:focus,
    [type="lname"]:focus,
    [type="subject"]:focus,
    [type="message"]:focus,
    textarea:focus {
      outline: none;
      color: white;
    }

    //disable browser autofill behaviour
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-text-fill-color: white;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  .socialIcons {
    display: flex;
    margin-top: 40px;
    gap: 20px;
    align-items: center;
    cursor: pointer;
    
  }

  .socialInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 40px;
    color: white;

    .socialIcon {
      vertical-align: middle;
      margin-right: 15px;
    }

    .branch {
      margin-top: 20px;

      &:first-of-type {
        margin-top: 0;
      }

      .title {
        display: block;
        font-size: large;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }

  .progressBar {
    height: 5px;
    background-color: rgba(102, 45, 144, 1);
    border-end-end-radius: 5px;
    border-bottom-left-radius: 5px;
    position: absolute;
    bottom: 14%;
    left: 0%;
    color: grey;
  }

  .characterCount {
    position: absolute;
    top: 80%;
    color: grey;
    bottom: 0;
    right: 2%;
    z-index: -1;
  }

  .formSpiderDesktop {
    .bubble {
      position: absolute;
      background: rgba(0, 0, 0, 0.6);
      color: #ffffff;
      font-family: Poppins;
      font-size: 1vw;
      line-height: 1vw;
      text-align: left;
      width: 50%;
      height: 25%;
      border-radius: 10px;
      padding: 0px;
      top: 5%;
      right: 70%;

      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: transparent rgba(0, 0, 0, 0.6);
        border-width: 20px 0 20px 20px;
        top: 39%;
        right: -20px;
        margin-top: -20px;
      }

      .bubbleDescription {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-48%, -50%);
        width: 90%;

        .webster {
          color: #0071bb;
          font-weight: bold;
          margin-bottom: 10px;
        }
      }
    }

    .formSpiderPictureDesktop {
      width: 24vw;
      height: auto;
    }
  }

  .formSpiderMobile {
    margin-top: 230px;
    position: relative;

    .bubbleMobile {
      position: absolute;
      background: #000000;
      color: #ffffff;
      font-family: Poppins;
      font-size: 3vw;
      text-align: left;
      width: 40%;
      height: 35%;
      border-radius: 10px;
      padding: 0px;
      bottom: 105%;
      left: 10%;

      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: #000000 transparent;
        border-width: 20px 20px 0;
        bottom: -20px;
        left: 50%;
        margin-left: -20px;
      }

      .bubbleMobileDescription {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-47%, -50%);
        width: 90%;
      }

      .webster {
        color: #0071bb;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }

    .formSpiderPictureMobile {
      width: 60%;
      height: 100%;
    }
  }
}

@import "./ContactUsMediaQueries.scss";
