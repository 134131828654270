@import "../../../scss/global.scss";

.descriptionContainerExtraordinary {
  cursor: default;

  .carousel {
    float: right;

    .slide {
      text-align: right;

      a {
        color: white;
        font-weight: 500;

        &:hover {
          filter: brightness(70%);
        }
      }
    }
  }
  
  #extraordinaryDescription {
    &:focus {
      outline: none;
    }
  }
}

@media only screen and(min-width : $Mobile) {
  .whatWeDoContainer {

    .descriptionContainerExtraordinary {
      position: absolute;
      top: -5vh;
      right: 100%;
      // display: flex;
      flex-direction: column;
      text-align: right;
      font-family: Poppins;
      animation: overlayfadein 1s;

      .carousel {
        min-height: 460px;
      }

      .title {
        color: white;
        font-size: 28px;
        line-height: 54px;
        font-weight: 700;
      }
      
      .text {
        font-family: Poppins;
        font-size: 16px;
        line-height: 22.4px;
        color: grey;
        font-weight: 400;
        width: 300px;
        text-align: right;
      }

      .xIcon{
        height: auto;
        width: 7%;    
       }
    }

    .seeMoreDesktopExtraordinary {
      position: absolute;
      left: 0%;
      width: 160px;

      .title {
        color: white;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
      }

      .icon{
        justify-content: space-between;
        align-items: center;
        display: flex;
        font-size: 10px;
        font-weight: 400;
        line-height: 36px;
        width: 66px;
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }
}

@media only screen and(min-width : $Tablet) {
  .whatWeDoContainer {

    .descriptionContainerExtraordinary {
      .carousel {
        min-height: 400px;
      }

      .text {
        font-size: 16px;
        width: 100%;
      }

      .xIcon{
        height: auto;
        width: 5%;    
       }
    }

    .seeMoreDesktopExtraordinary {
      position: absolute;
      left: 0%;
      width: 220px;

      .title {
        font-size: 16px;
        line-height: 30px;
      }

      .icon{
        font-size: 14px;
        width: 90px;
        line-height: unset;
      }
    }
  }
}
@media only screen and(min-width : $Desktop) {
  .whatWeDoContainer {

    .descriptionContainerExtraordinary {
      .carousel {
        min-height: 530px;
      }

      .text {
        font-size: 18px;
      }
    }

    .seeMoreDesktopExtraordinary {
      position: absolute;
      left: 0%;

      .title {
        font-size: 20px;
        line-height: 36px;
      }

      .icon{
        font-size: 14px;
        width: 100px;
      }
    }
  }
}

@media only screen and(min-width : $LargeDesktop) {
  .whatWeDoContainer {

    .descriptionContainerExtraordinary {

      .text {
        font-size: 18px;
        line-height: 30px;
      }
    }

    .seeMoreDesktopExtraordinary {
      position: absolute;
      left: 25%;

      .icon{
        font-size: 16px;
      }
    }
  }
}

@media only screen and(min-width : $ExtraLargeDesktop) {
  .whatWeDoContainer {

    .seeMoreDesktopExtraordinary {
      .carousel {
        min-height: 600px;
      }

      left: 40%;

      .icon{
        font-size: 16px;
      }
    }
  }
}

@media only screen and(min-width : $XXLDesktop) {
  .whatWeDoContainer {

    .descriptionContainerExtraordinary {

      .text {
        font-size: 26px;
        line-height: 32px;
      }
    }

    .seeMoreDesktopExtraordinary {
      position: absolute;
      left: 30%;

      .title {
        font-size: 26px;
        line-height: 36px;
      }

      .icon{
        font-size: 22px;
        width: 150px;
      }
    }
  }
}
@media only screen and(min-width : $UHD) {
  .whatWeDoContainer {

    .descriptionContainerExtraordinary {
      .carousel {
        min-height: 650px;
      }

      .title {
        font-size: 42px;
      }

      .text {
        font-size: 32px;
        line-height: 50px;
        width: 1000px;
      }
    }
  }
}
