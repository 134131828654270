@import "../../../../../scss/global.scss";

@media only screen and(max-width : $Tablet-Desktop) {
  .menuOverlay {
    display: none;

    .mobile {
      display: none;
    }
  }
}

@media only screen and(max-width : $Mobile) {
  .MenuContainer {
    animation: none;
    .desktop {
      display: none;
    }

    ul {
      padding-inline-start: 40px;
    }

   .menu {
    .mobile {
      position: absolute;
      background: url("../../../../../assets/coloredBackgroundMobile.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
      width: 100vh;
      left: 0%;
      top: 100%;
      animation: overlayfadein 0.7s;

      .itemsContainer {
        font-family: Poppins;
        font-weight: 700;
        font-size: 36px;
        line-height: 54px;
        color: white;
        margin-top: 26px;
        
        .mobileLink {
          display:block;
        }

        .slide {
          display: none;
        }

        .contactBtn {
          .desktopB {
            display: none;
            
          }

          .mobileB {
            padding: 4px 16px;
            margin-top: 34.5px;
            display: flex;
            width: 35%;
            height: 48px;
            background-color: #662d90;
            border-radius: 8px;
            justify-content: center;
            text-align: center;
            align-items: center;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;

            .contact {
              text-decoration: none;
              color: white;
            }

            .clock {
              height: 40%;
              width: 10%;
            }
          }
        }

        .navItem {
          margin-bottom: 5px;

          a {
            color: white;
            text-decoration: none;
          }
        }

        .socialsContainer {
          margin-top: 154px;

          .socials {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 200px;
            cursor: pointer;
          }

          .footer {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            margin-top: 26px;
            color: grey;
          }
        }
      }
    }
  }
}
   }
    
@media only screen and(min-width: $Mobile) and(max-width : 1300px) {
  .MenuContainer {
    margin-right: 15px;
  }
}

@media only screen and(min-width : $ExtraLargeDesktop) {
  .MenuContainer {
    .desktop {
      font-size: 20px;
      line-height: 30px;
    }
  }
}
