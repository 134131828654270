.spiderLinkedFooter {
    height: 100px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    margin-top: 200px;
    display: flex;
    font-family: Poppins;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .cookies {
    display: flex;
    gap: 20px;
    font-size: 16px;
    color: white;
  }
  
  .footerText {
    color: grey;
    font-size: 14px;
    margin-top: 10px;
  }
