@import "../../../../../scss/global.scss";

@media screen and (max-width: $Desktop) {
  .homePageSubTitle {
    font-size: 8vw;
    color: white;
    position: relative;
    margin-top: 10%;
  }
}

@media screen and (max-width: $Tablet) {
  .homePageSubTitle {
    font-size: 9vw;
    color: white;
    position: relative;
  }

  .circlesContainer {
    padding-top: 18%;
  }
}

@media screen and (min-width: $ExtraLargeDesktop) {
  .CirclesDesktop {

    .scrollContainer {
      left: 50%;

      .scrollText {
        position: absolute;
        transform: translateY(-80px) translateX(20px);
        color: white;
        font-size: 1vw;
      }
      
      .iconScroll {
        width: 60px;
        height: 110px;
        margin-left: -20px;
        top: 50%;
        margin-top: -35px;
        box-shadow: inset 0 0 0 1px #fff;
        border-radius: 25px;

        &:before {
          content: "";
          width: 10px;
          height: 10px;
          background: #fff;
          margin-left: -4px;
          top: 8px;
        }
      }
    }
  }
}

@media screen and (min-width: $XXLDesktop) {
  .CirclesDesktop {

    .scrollContainer {
      left: 48%;

      .scrollText {
        position: absolute;
        transform: translateY(-80px) translateX(20px);
        color: white;
        font-size: 1vw;
      }

      .iconScroll {
        width: 60px;
        height: 110px;
        margin-left: -20px;
        top: 50%;
        margin-top: -35px;
        box-shadow: inset 0 0 0 1px #fff;
        border-radius: 25px;

        &:before {
          content: "";
          width: 10px;
          height: 10px;
          background: #fff;
          margin-left: -4px;
          top: 8px;
          border-radius: 4px;
          -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
          -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
          -webkit-animation-name: scroll;
          animation-name: scroll;
        }
      }
    }
  }
}
