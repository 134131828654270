.TechStack{
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
  line-height: 30.4px;
  color: grey;
  margin-top: 10%;

  .techStackTitle {
    margin-left: 13%;
  }

  .techStackDescription {
    width: 75%;
    margin-left: 13%;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 16px;
    line-height: 160%;

    .subTitle {
      display: block;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: white;

      &:nth-of-type(2) {
        margin-top: 15px;
      }
    }

    div {
      max-width: 45%;
      overflow-wrap: break-word;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      ul {
        padding-inline-start: 0;
        margin-block-start: 0.4em;
        margin-block-end: 0.4em;

        li {
          margin-left: 15%;
        }
      }
    }

    .extraServices {
      .subTitle:nth-of-type(2) {
        margin-top: 36.5%;
      }

      ul {
        li {
          margin-left: 5%;
        }
      }
    }
  }
}

@import "./TechStackMediaQueries.scss";
