@import "../../../scss/global.scss";

.overlayContainer {
  position: fixed;
  z-index: $overlayContainerMobile;
  animation: overlayfadein 1s;
  animation-fill-mode: forwards;
  height: 100%;
  width: 100%;
  top: 0%;
  bottom: 0%;
  right: 0%;
  background-image: url("../../../assets/coloredBackgroundMobile.png");
  background-size: cover;
  overflow: scroll;

  .carousel {
    min-height: 460px;

    .slide {
      text-align: left;
    }

    a {
      color: white;
      font-weight: 500;

      &:hover {
        filter: brightness(70%);
      }
    }
  }

  .overall {
    position: absolute;
    top: 10%;
  }

  .overlayTextContainer::-webkit-scrollbar {
    display: none;
  }

  .overlayTextContainer {
    height: 600px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 25.6px;
    font-size: 16px;
    max-width: 690px;
    margin-left: 32px;
    margin-right: 32px;

    color: grey;

    .overlayTitle {
      font-weight: 700;
      line-height: 42px;
      font-size: 28px;
      color: white;
      margin-bottom: "18px";
    }

    .overlayExtraordinarySpiderMobile {
      transform: scale(0.65) translateX(-70px);
    }
  }
}


