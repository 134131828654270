@import "../../../scss/global.scss";

.overlayDesktopContainer {
    position: fixed;
    z-index: $overlayContainer;
    height: 100%;
    width: 100%;
    bottom: 0%;
    right: 0%;
    background-color: rgba(0, 0, 0, 0.95);
    animation: overlayfadein 1s;
    animation-fill-mode: forwards;
    cursor: pointer;

  
  }

  @keyframes overlayfadein {
    0%    { opacity: 0; }
    20%   { opacity: 0; }
    80%   { opacity: 1; }
    100%  { opacity: 1; }
  }
  
