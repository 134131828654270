@import "./IntroScreenMixins.scss";
@import "../../../scss/global.scss";

@keyframes fadeAndScale {
  0% {
    opacity: 0;
    transform: scale3d(0.75, 0.75, 1);
    @include logoFadePosition();
  }

  40% {
    opacity: 0;
    transform: scale3d(0.75, 0.75, 1);
    @include logoFadePosition();
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    @include logoFadePosition();
  }
}

@keyframes backgroundMoveLeft {
  0% {
    opacity: 0;
  }

  50% {
    transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes moveLeft {
  0% {
    right: 20%;
  }

  50% {
    right: 0%;
  }

  100% {
    left: 0%;
  }
}

//all keyframes need to have have scale property

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }

  90% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes textSlide {
  0% {
    transform: translateX(60px);
    opacity: 0;
  }
  60% {
    transform: translateX(70px);
    opacity: 0;
  }
  70% {
    transform: translateX(190px);
    opacity: 1;
  }
  80% {
    transform: translateX(200px);
    opacity: 1;
  }

  100% {
    transform: translateX(170px);
    opacity: 1;
  }
}

@import "./IntroScreenMediaQueries.scss";
