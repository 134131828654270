@import "../../../scss/global.scss";

@media only screen and(min-width : $Mobile) {
    ._map_span-3 {
      background: url("../../../assets/spiders/mobileSpiderNoGlow.png");
  
      background-size: contain;
      background-repeat: no-repeat;
    }
  
    ._map_span-3:hover {
      background: url("../../../assets/spiders/mobileSpiderGlow.png");
      background-size: contain;
  
      transform: scale(1.15) translateX(10px);
      background-repeat: no-repeat;
    }
  
    ._map_span-1 {
      background: url("../../../assets/spiders/extraordinarySpiderNoGlow.png");
      z-index: 4;
      background-size: contain;
      background-repeat: no-repeat;
    }
  
    ._map_span-1:hover {
      background: url("../../../assets/spiders/extraordinarySpiderGlow.png");
      transform: scale(1.2) translateX(10px);
      background-size: contain;
      background-repeat: no-repeat;
    }
  
    ._map_span-0 {
      background: url("../../../assets/spiders/webSpiderNoGlow.png");
  
      background-size: contain;
      background-repeat: no-repeat;
    }
  
    ._map_span-0:hover {
      background: url("../../../assets/spiders/webSpiderGlow.png");
      transform: scale(1.2) translateX(10px);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  