@import "../../../scss/global.scss";

.partnersContainer {
  width: 100%;
  height: 100%;
  margin-top: 10%;

  .partnersTitle {
    margin-left: 13%;
  }

  .hexagonsRelativeDiv {
    position: relative;
    width: 100%;
  }

  .hexagonsRow {
    display: flex;

    &.second {
      margin-bottom: 50px;
    }
    &.third {
      margin-top: -50px;
    }
  }

  .logoSvg {
    width: 100%;
    height: 100%;

    &.linkGraph {
      width: 100%;
      height: 25%;
      margin-top: 40%;
      object-fit: contain;

    }

    &.polyConseil {
     width: 110%;
     margin-left: -8%;
    }

   
  }
  
  .popUpLogoSvg {
    height: 90%;
    width: 90%;

    &.linkGraph {
      height: 80%;
      width: 80%;
      object-fit: contain;
    }

    &.tacitKnowledge {
      height: 80%;
      width: 80%;
    }

    &.polyconseil {
      height: 75%;
      width: 80%;
    }

    &.pinnacle {
      height:80%;
    }

  }
}

@import "./PartnersMediaQueries.scss";
