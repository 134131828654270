@import "./IntroScreenKeyFrames.scss";
@import "./IntroScreenMixins.scss";

.IntroScreen {
  height: 100vh;
  background-image: url("../../../assets/coloredBackground.png");
  background-size: cover;

  .animationContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-200%, -50%);

    &.fading {
      animation: fadeOut 0.6s;
      animation-timing-function: linear;
    }

    .spiderLogoContainer {
      animation: fadeAndScale 2s, 0.3s moveLeft 2s, 0.8s moveRight 2.2s;
      animation-fill-mode: forwards;
      width: fit-content;
      position: relative;
    }

    .spiderLinkedTextContainer {
      animation: textSlide 2.45s 0.75s;
      animation-fill-mode: forwards;
      position: absolute;
      bottom: 30.89%;
      opacity: 0;
    }
  }
}

@media only screen and(max-width : $Mobile) {
  .IntroScreen {
    background-image: url("../../../assets/coloredBackgroundMobile.png");
    background-size: cover;

    .animationContainer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-200%, -50%);
  
      &.fading {
        animation: fadeOut 0.6s;
        animation-timing-function: linear;
      }
  
      .spiderLogoContainer {
        transform: scale3d(0.4, 0.4, 0.4);
        animation: fadeAndScaleMobile 2.5s, 1s moveLeftMobile 2.5s;
        animation-fill-mode: forwards;
        width: fit-content;
        z-index: 2;
        position: relative;
      }
  
      .spiderLinkedTextContainer {
        animation: textSlide 1.75s 1.75s;
        animation-fill-mode: forwards;
        position: absolute;
        bottom: 30.89%;
        opacity: 0;
        z-index: 1;
      }
    }
  }
}
