@import "../../../scss/global.scss";

.descriptionContainerWeb {
  #webDescription {
    &:focus {
      outline: none;
    }
  }
}

@media only screen and(min-width : $Mobile) {
  .whatWeDoContainer {

    .seeMoreDesktopWeb {
      position: absolute;
      width: 150px;
      font-family: Poppins;
      left: 0%;
      top: 20%;

      .title {
        color: white;
        font-weight: 700;
        font-size: 14px;
        line-height: 36px;
      }

      .icon {
        justify-content: space-between;
        align-items: center;
        display: flex;
        font-size: 10px;
        font-weight: 400;
        width: 70px;
        color: rgba(255, 255, 255, 0.4);
      }
    }

    .descriptionContainerWeb {
      position: absolute;
      top: 11%;
      left: 80%;
      display: "flex";
      flex-direction: column;
      text-align: left;
      font-family: Poppins;
      animation: overlayfadein 1s;

      .title {
        color: white;
        font-size: 28px;
        line-height: 54px;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
      }

      .text {
        font-family: Poppins;
        font-size: 16px;
        line-height: 22.4px;
        color: grey;
        font-weight: 400;
        width: 300px;
        text-align: left;
      }

      .xIcon {
        height: auto;
        width: 5%;
         
       }
    }
  }
}

@media only screen and(min-width : $Tablet) {
  .whatWeDoContainer {

    .descriptionContainerWeb {

      .text {
        font-size: 16px;
        width: 570px;
      }
    }

    .seeMoreDesktopWeb {
      position: absolute;
      width: 150px;
      font-family: Poppins;
      left: 10%;
      top: 30%;

      .title {
        font-size: 18px;
        line-height: 36px;
      }

      .icon {
        font-size: 14px;
        width: 100px;
      }
    }
  }
}

@media only screen and(min-width : $Desktop) {
  .whatWeDoContainer {

    .descriptionContainerWeb {

      .text {
        font-size: 18px;
      }
    }

    .seeMoreDesktopWeb {
      position: absolute;
      width: 150px;
      font-family: Poppins;
      left: 20%;
      top: 40%;

      .title {
        font-size: 20px;
        line-height: 36px;
      }

      .icon {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

@media only screen and(min-width : $LargeDesktop) {
  .whatWeDoContainer {
    .descriptionContainerWeb {
      .text {
        font-size: 18px;
        line-height: 30px;
      }
    }

    .seeMoreDesktopWeb {
      position: absolute;
      width: 150px;
      left: 35%;
      top: 50%;

      .icon {
        font-size: 16px;
      }
    }
  }
}

@media only screen and(min-width : $LargeDesktop) {
  .whatWeDoContainer {

    .descriptionContainerWeb {

      .text {
        font-size: 18px;
        line-height: 30px;
      }
    }
    .seeMoreDesktopWeb {
      position: absolute;
      width: 150px;
      left: 35%;
      top: 50%;

      .icon {
        font-size: 16px;
      }
    }
  }
}
@media only screen and(min-width : $XXLDesktop) {
  .whatWeDoContainer {

    .descriptionContainerWeb {

      .text {
        font-size: 26px;
        line-height: 36px;
        width: 700px;
      }
    }

    .seeMoreDesktopWeb {
      width: 150px;

      .title {
        font-size: 30px;
      }

      .icon {
        font-size: 26px;
        width: 180px;
      }
    }
  }
}
@media only screen and(min-width : $UHD) {
  .whatWeDoContainer {
    .descriptionContainerWeb {

      .title {
        font-size: 42px;
      }

      .text {
        font-size: 32px;
        line-height: 50px;
        width: 1000px;
      }
    }
  }
}
