@import "../../../scss/global.scss";

.navBar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  z-index: $NavBar;

  .menuPosition {
    position: absolute;
    right: 0%;
    top: -30%;
  }

  .hamburgerBtn {
    display: none;
  }

  .mobileLogoSvg {
    animation: slideFromAbove 3.5s;
    margin-left: 28px;
    cursor: pointer;
  }

}

.navBarOverlay {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: black;

  .mobileLogoSvg {
    height: 60%;
    width: 10%;
    margin-bottom: 8px;
    margin-left: 5px;
  }

  .hamburgerBtn {
    display: block;
    background-color: #662d90;
    width: 38px;
    height: 38px;
    border-radius: 8px;
    margin-right: 21px;
    border: none;
    margin-top: auto;
    margin-bottom: auto;

    .hamburger {
      display: block;
      margin: auto;
    }

    .xIcon {
      display:block;
      margin: auto;
    }
  }
}

@import "./NavBarMediaQueries.scss";
