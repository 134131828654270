@import "../../../../../scss/global.scss";

.MenuContainer {
  flex-direction: column;
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-right: 30px;
  animation: slideFromRight 3.5s;
  
  @keyframes slideFromRight {
    0% {
      transform: translateX(200%);
    }
    80% {
      transform: translateX(0%);
    }
  }
  .MenuContainerOverlay {
    display: none;
  }
  .socials {
    display: none;
  }
  .footer {
    display: none;
  }

  ul {
    position: relative;
    padding-inline-start: 0px;

    .contactBtn {
      .desktopB {
        padding: 6px 16px 6px 16px;
        background-color: rgba(102, 45, 144, 1);
        border-radius: 8px;
        color: white;
        width: fit-content;
        margin-bottom: 15px;

        .contact {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: white;
        }

        .clock {
          vertical-align: middle;
          width: 18px;
          height: 25px;
          margin-left:4px;
        }
      }

      .mobileB {
        display: none;
      }
    }

    li {
      list-style: none;
      padding: 6px 16px 6px 16px;
      margin-top: 8px;
      border-radius: 8px;
      a {
        color: white;
        text-decoration: none;
      }

      .mobileLink {
        display: none;
      }
    }

    .slide {
      transition: 0.8s;
      position: absolute;
      border-radius: 8px;
      border-color: rgba(102, 45, 144, 1) !important;
      background-color: rgba(0, 0, 0, 0.4);
      border-left: 4px solid;
      height: 10.5%;
      width: 78.5%;
      left: -5%;
      z-index: -1;
      opacity: 0;
    }
    li:nth-child(2).active ~ .slide {
      opacity: 1;
      bottom: calc(71.4% - 7px);
    }
    li:nth-child(3).active ~ .slide {
      opacity: 1;
      bottom: calc(57.12% - 6px);
    }
    li:nth-child(4).active ~ .slide {
      opacity: 1;
      bottom: calc(42.84% - 6px);
    }
    li:nth-child(5).active ~ .slide {
      bottom: calc(28.56% - 5px);
      opacity: 1;
    }
    li:nth-child(6).active ~ .slide {
      bottom: calc(14.28% - 5px);
      opacity: 1;
    }
    li:nth-child(7).active ~ .slide {
      bottom: -5px;
      opacity: 1;
    }
  }
}
@import "./MenuMediaQueries.scss";
