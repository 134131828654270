@import "./IntroScreenMixins.scss";
@import "../../../scss/global.scss";

@media only screen and(max-width : $Mobile) {
  @keyframes moveRightMobile {
    0% {
    }

    90% {
      transform: translateX(-10px);
    }

    100% {
      transform: translateX(0px);
      transform: scale(0.4);
    }
  }

  @keyframes fadeAndScaleMobile {
    0% {
      opacity: 0;
      
    }

    40% {
      opacity: 0;
      transform: scale(0.3);
      @include logoFadePositionMobile();
    }

    100% {
      opacity: 1;
      transform: scale(0.4);
      @include logoFadePositionMobile();
    }
  }

  @keyframes textSlide {
    0% {
      transform: translateX(60px) scale3d(0.4, 0.4, 0.4);
      opacity: 0;
    }
    60% {
      transform: translateX(70px);
      opacity: 0;
    }
    70% {
      transform: translateX(190px);
      opacity: 1;
    }
    80% {
      transform: translateX(200px);
      opacity: 1;
    }

    100% {
      transform: translateX(170px);
      opacity: 1;
    }
  }

  @keyframes moveLeftMobile {
    70% {
      left:60%; 
    }

    100% {
      left: 68%;
    }
  }
}

@media only screen and(min-width : $XXLDesktop) {
  @keyframes moveRight {
    0% {
      transform: translateX(0) scale3d(2, 2, 2);
    }

    90% {
      transform: translateX(-10px) scale3d(2, 2, 2);
    }

    100% {
      transform: translateX(0px) scale3d(2, 2, 2);
    }
  }

  @keyframes fadeAndScale {
    0% {
      opacity: 0;
      transform: scale3d(1.75, 1.75, 2);
      @include logoFadePosition();
    }

    40% {
      opacity: 0;
      transform: scale3d(1.75, 1.75, 2);
      @include logoFadePosition();
    }

    100% {
      opacity: 1;
      transform: scale3d(2, 2, 2);
      @include logoFadePosition();
    }
  }

  @keyframes textSlide {
    0% {
      transform: translateX(70px) scale3d(2, 2, 2);
      opacity: 0;
    }
    60% {
      transform: translateX(70px);
      opacity: 0;
    }
    70% {
      transform: translateX(190px);
      opacity: 1;
    }
    80% {
      transform: translateX(200px);
      opacity: 1;
    }

    100% {
      transform: translateX(170px);
      opacity: 1;
    }
  }

  @keyframes moveLeft {
    0% {
      right: 20%;
      transform: scale3d(2, 2, 2);
    }

    50% {
      right: 0%;
    }

    100% {
      left: -160%;
    }
  }
}
