@import "../../../../../scss/global.scss";
@import "../../../styles/Keyframes.scss";

.CirclesDesktop {
  .rotatingCircle {
    transform-origin: center;
    animation: rotate 15s infinite;
    transform-box: fill-box;
    -webkit-transform: translateZ(0) translate3d(0, 0, 0);
    -moz-transform: translateZ(0) translate3d(0, 0, 0);
    -ms-transform: translateZ(0) translate3d(0, 0, 0);
    -o-transform: translateZ(0);
    transform: translateZ(0) translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .circlesContainer {
    height: fit-content;
    width: 100%;
    font-family: Poppins;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20%;
    overflow-x: clip;

    .circlesRelativeDiv {
      transform: translate(-0%, 0%) tranlateZ(0);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
      backface-visibility: hidden;
      margin-left: 13%;
      margin-top: 15vh;
      width: 100%;
      height: fit-content;
      color: white;
      font-weight: 700;
      font-family: Poppins;
      color: grey;
      position: relative;
      animation: SlideLeftText 4s;
      transform: translateX(0%);
      animation-fill-mode: unset;

      .subTitle {
        font-size: 5.2vw;
        color: white;
        position: relative;
        margin-top: 10%;
      }

      .title {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 5%;
        margin-bottom: 30px;
        font-size: 1vw;
      }

      .planets {
        position: absolute;
        bottom: 0%;
        opacity: 1;
        right: 35%;
        transform: translateX(40%) translateY(26.5%) translateZ(0);
        animation-fill-mode: unset;
        z-index: -5;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        animation: slideRightCircle 3.5s 1.2s;
      }
    }
  }

  .scrollContainer {
    animation: fadeInFromBottom 4s;
    z-index: 500;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 12%;
    left: 48%;
    transform: translateZ(0) translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    .scrollText {
      position: absolute;
      transform: translateY(-50px);
      color: white;
      font-size: 1vw;
    }

    .iconScroll {
      width: 40px;
      height: 70px;
      margin-left: -20px;
      top: 50%;
      left: 50%;
      margin-top: -35px;
      box-shadow: inset 0 0 0 1px #fff;
      border-radius: 25px;
      position: absolute;

      &:before {
        content: "";
        width: 8px;
        height: 8px;
        background: #fff;
        margin-left: -4px;
        top: 8px;
        border-radius: 4px;
        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-name: scroll;
        animation-name: scroll;
        left: 50%;
        position: absolute;
      }
    }
  }
}

@import "./CirclesDesktopMediaQueries.scss";
