@import "../../../scss/global.scss";

@media only screen and(max-width : $Mobile) {
    .navBar {
      background-color: black;
      margin-top: 0px;
      height: 70px;
      align-items: center;

      .mobileLogoSvg {
          height: 60%;
          width: 10%;
          margin-bottom: 8px;
          margin-left: 5px;
          animation: none;
        }

        .menuPosition {
          position: static;
        }
  
      .hamburgerBtn {
        display: block;
        background-color: #662d90;
        width: 38px;
        height: 38px;
        border-radius: 8px;
        margin-right: 21px;
        border: none;
        margin-top: 0px;

        .hamburger {
          display: block;
          margin: auto;
        }
        
        .xIcon {
          display:block;
          margin: auto;
        }
      }
    }
  }
  
