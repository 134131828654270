@import "../../../scss/global.scss";

@media only screen and (max-width: $Mobile) {
  .TechStack {
    .techStackTitle {
      margin-left: 0%;
    }

    .techStackDescription {
      margin-left: 30px;
      flex-direction: column;
      font-size: 14px;
      line-height: 140%;

      .subTitle {
        font-size: 18px;
        line-height: 27px;
      }

      div {
        max-width: 100%;
        overflow-wrap: break-word;
      }

      .extraServices {
        margin-top: 15px;

        .subTitle:nth-of-type(2) {
          margin-top: 20px;
        }
      }
    }
  }
}

@media only screen and(min-width : $ExtraLargeDesktop) {
  .TechStack {
    .techStackDescription {
      font-size: 24px;
      line-height: 50px;
    }
  }
}

@media only screen and(min-width : $UHD) {
  .TechStack {
    .techStackDescription {
      font-size: 32px;
      line-height: 50px;
    }
  }
  
}
