@import "../../../scss/global.scss";

.homePage {
  .mobile {
    display: none;
  }
}

@media screen and (max-width: $Mobile) {
  .homePage {
    -webkit-transform: translateZ(0) translate3d(0, 0, 0);
    -moz-transform: translateZ(0) translate3d(0, 0, 0);
    -ms-transform: translateZ(0) translate3d(0, 0, 0);
    -o-transform: translateZ(0);
    transform: translateZ(0) translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;

    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }
  }
}

@media screen and (min-width: 770px) {
  .homePage {
    .mobile {
      display: none;
    }
  }
}


