@import "../../../../../scss/global.scss";

.CirclesMobile {
  .pageTitle {
    position: absolute;
    bottom: 80%;
    left: 7%;
    font-size: 22px;
    color: white;
  }

  .pageSubTitle {
    position: absolute;
    font-size: 37px;
    top: 65%;
    left: 5%;
    color: white;
    font-weight: bold;
    will-change: transform;
  }

  .planetsMobile {
    position: absolute;
    top: 20%;
    z-index: $planetContainer;
    will-change: transform;
    height: 74%;
  }
}

@media screen and (max-width: 770px) {
  .CirclesMobile {
    .pageSubTitle {
      left: 8%;
    }

    .planetsMobile {
      top: 13%;
      height: 100%;
      left: 19%;
    }
  }
}

@media screen and (max-width: 570px) {
  .CirclesMobile {
    .pageSubTitle {
      position: absolute;
      font-size: 37px;
      top: 65%;
      left: 5%;
      color: white;
      font-weight: bold;
      will-change: transform;
    }

    .planetsMobile {
      position: absolute;
      top: 20%;
      z-index: $planetContainer;
      will-change: transform;
      height: 74%;
    }
  }
}
