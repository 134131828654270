.AboutUs{
  font-family: Poppins;
  font-weight: 400;
  font-size: 18px;
  line-height: 30.4px;
  color: grey;
  padding-top: 10%;

  .aboutUsTitle {
    margin-left: 13%;
  }

  .aboutUsDescription {
    width: 80%;
    margin-left: 13%;
    margin-top: 25px;
  }
}

@import "./AboutUsMediaQueries.scss";
