@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    80% {
      transform: rotate(360deg);
    }
  }

  @keyframes slideRightCircle {
    0% {
      transform: translateX(200%);
      opacity: 0;
    }
  
    100% {
      transform: translate(40%, 26.5%);
    }
  }
  
  @keyframes SlideLeftText {
    0% {
      transform: translateX(-250%);
      opacity: 0;
    }
  
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes slideFromBottom {
    0% {
      transform: translateY(100%);
      opacity: 0;
     
    }
  
    100% {
      transform: translateY(-100%);
      opacity: 1;
      
    }
  }

  @keyframes slideFromBottomDesktop {
    0% {
      transform: translateY(33vh);
      opacity: 0;
    }
  
    100% {
      transform: translateY(-8vw);
      opacity: 1;    
    }
  }

  @keyframes slideFromBottomXXLDesktop {
    0% {
      transform: translateY(33vh);
      opacity: 0;
    }
  
    100% {
      transform: translateY(-15vw);
      opacity: 1;    
    }
  }
  
  
  
  @-webkit-keyframes scroll {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(46px);
    }
  }
  @keyframes scroll {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(46px);
    }
  }

  @keyframes fadeInFromBottom {
   0%{
     opacity: 0;
     bottom: -50%;
   }

   100%{
      opacity: 1;
      bottom: 12%;
   }
  }
