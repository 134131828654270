@import "../../scss/global.scss";

.PageTitle {
  color: white;
  z-index: 1;

  span {
    display: block;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  .description {
    max-width: 296px;
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
    
  }
}

@media only screen and(max-width : 759px) {

  .PageTitle {
    margin-left: 30px;
  }
}

@media only screen and(min-width : $XXLDesktop) {
  .PageTitle {

    .title {
      font-size: 32px;
    }

    .description {
      font-size: 38px;
      max-width: 400px;
      margin-top: 40px;
      margin-left: 0px;
    }
  }
}

@media only screen and(min-width : $UHD) {
  .PageTitle {
    
    .title {
      font-size: 42px;
    }

    .description {
      font-size: 48px;
    }
  }
}
