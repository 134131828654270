@import "../../../scss/global.scss";


@media only screen and(max-width : $Mobile) {
    .WhatWeDo {
      display: none;
    }
  
    .whatWeDoMobile {
      display: block;
      position: relative;
  
      .mobileTitle {
        font-family: Poppins;
        font-weight: 500;
        font-size: 24px;
        line-height: 33px;
        color: white;
        cursor: pointer;
      }

      .extraordinaryMobileSizeContainer {
        .mobileTitle {
          font-size: 120%;
        }
      }
  
      .seeMoreMobileSize {
        font-family: Poppins;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.4);
        cursor: pointer;
  
        letter-spacing: 0.1em;
  
        .seeMoreSpace{
          vertical-align: middle;
          display: inline-block;
          margin-right: 8px;
        }
      }

      .extraordinaryMobileSizeContainer {
          @include placeBehindMap;
          left: 45%;
          bottom: 20%;
      }

      .mobileMobileSizeContainer {
        @include placeBehindMap;
        left: 20%;
        top: 50%;
      }

      .webMobileSizeContainer {
        @include placeBehindMap;
        left: 45%;
        top: 15%; 
      }
    }
  }
  