@import "../../../scss/global.scss";

@media only screen and (max-width: $Mobile) {
  .HowWeHelp {
    .howWeHelpTitle {
      margin-left: 0%;
    }

    .howWeHelpDescription {
      margin-left: 5%;
      width: 90%;
      flex-direction: column;
      font-size: 16px;
      line-height: 140%;

      &.mobile {
        display: block;
      }

      &.desktop {
        display: none;
      }

      .subTitle {
        font-size: 20px;
        line-height: 29px;
      }

      div {
        max-width: 100%;
        overflow-wrap: break-word;
      }
    }
  }
}

@media only screen and(min-width : $ExtraLargeDesktop) {
  .HowWeHelp {
    .howWeHelpDescription {
      font-size: 24px;
      line-height: 50px;

      .subTitle {
        font-size: 26px;
        line-height: 52px;
      }
    }
  }
}

@media only screen and(min-width : $UHD) {
  .HowWeHelp {
    .howWeHelpDescription {
      font-size: 32px;
      line-height: 50px;

      .subTitle {
        font-size: 34px;
        line-height: 52px;
      }
    }
  }
}
