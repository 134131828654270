@import "../../../scss/global.scss";

.WhatWeDo {
  padding: 0 13.61111%;
  padding-top: 7.1111vh;
  height: fit-content;
  z-index: $spiderDesk;
  position: relative;
  
  .image-map__map {
    &:hover {
      cursor: pointer;
      
    }
  }

  .whatWeDoContainer {
    background-repeat: no-repeat;
    height: fit-content;
    margin-top: 58px;
    position: relative;
    z-index: 3;
    font-family: Poppins;
    font-weight: 400;
    font-size: 24px;

    @keyframes overlayfadein {
      0%    { opacity: 0; }
      20%   { opacity: 0; }
      80%   { opacity: 1; }
      100%  { opacity: 1; }
    }
  }
}

@import "./HoveredSpiders.scss";

.whatWeDoMobile {
  display: none;
}


@import "./extraordinarySpiderMediaQueries.scss";
@import "./TheMobileSpiderMediaQueries.scss";
@import "./WebSpiderMediaQueries.scss";
@import "./MobileSize.scss";
