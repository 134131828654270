@import "../../../scss/global.scss";

@media only screen and (max-width: $Mobile) {
  .AboutUs {
    .aboutUsTitle {
      margin-left: 0%;
    }

    .aboutUsDescription {
      margin-left: 30px;
    }
  }
}

@media only screen and(min-width : $ExtraLargeDesktop) {
  .AboutUs {
    .aboutUsDescription {
      font-size: 24px;
      line-height: 50px;
    }
  }
}

@media only screen and(min-width : $UHD) {
  .AboutUs {
    .aboutUsDescription {
      font-size: 32px;
      line-height: 50px;
    }
  }
  
}
