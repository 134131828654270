@import "../../../scss/global.scss";

.overlayPartnersContainer {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url("../../../assets/coloredBackgroundMobile.png");
  background-size: cover;
  z-index: $overlayContainerMobile;
  animation: overlayfadein 1s;
  animation-fill-mode: forwards;

  .overlayPartnersDescriptionContainer {
    margin-top: 150px;
    max-width: 80%;
    text-align: left;
    margin-left: 30px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    color: grey;
  
    

    .overlayPartnersText {
      height: 500px;
      overflow: scroll;
      white-space: pre-wrap;


      &::-webkit-scrollbar{
        display: none;
      }
    }

    .overlayPartnersTitle {
      margin-bottom: 20px;
      font-size: 28px;
      line-height: 42px;
      font-weight: 700;
      color: white;
    }

    .partnersLogo {
      height: 18vh;
      width: 45vw;
    }
  }
}
