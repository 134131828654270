@import "../../../scss/global.scss";

.descriptionContainer {

  #mobileDescription {
    &:focus {
      outline: none;
    }
  }
}

@media only screen and(min-width : $Mobile) {
  .whatWeDoContainer {

    .descriptionContainer {
      position: absolute;
      top: 15%;
      right: 100%;
      display: "flex";
      flex-direction: column;
      text-align: right;
      font-family: Poppins;
      animation: overlayfadein 1s;

      .title {
        color: white;
        font-size: 28px;
        line-height: 54px;
        font-weight: 700;
      }

      .text {
        font-family: Poppins;
        font-size: 16px;
        line-height: 22.4px;
        color: grey;
        font-weight: 400;
        width: 300px;
        text-align: right;
      }

      .xIcon {
        height: auto;
        width: 5%;
      }
    }

    .seeMoreDesktopMobileSpider {
      position: absolute;
      left: 80%;

      .title {
        color: white;
        font-weight: 700;
        font-size: 14px;
        line-height: 36px;
      }

      .icon{
        justify-content: space-between;
        align-items: center;
        display: flex;
        font-size: 10px;
        font-weight: 400;
        width: 78px;
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }
}

@media only screen and(min-width : $Tablet) {
  .whatWeDoContainer {

    .descriptionContainer {

      .text {
        font-size: 16px;
        width: 550px;
      }
    }

    .seeMoreDesktopMobileSpider {
      position: absolute;
      left: 80%;

      .title {
        font-size: 18px;
        line-height: 36px;
      }

      .icon{
        font-size: 14px;
        width: 100px;
      }
    }
  }
}

@media only screen and(min-width : $Desktop) {
  .whatWeDoContainer {

    .descriptionContainer {

      .text {
        font-size: 18px;
      }
    }

    .seeMoreDesktopMobileSpider {
      position: absolute;
      left: 80%;
      width: 500px;

      .title {
        font-size: 20px;
        line-height: 36px;
      }

      .icon{
        font-size: 16px;
      }
    }
  }
}

@media only screen and(min-width : $LargeDesktop) {
  .whatWeDoContainer {

    .descriptionContainer {
      .text {
        font-size: 18px;
        line-height: 30px;
      }
    }

    .seeMoreDesktopMobileSpider {
      position: absolute;
      left: 80%;

      .icon{
        font-size: 16px;
      }
    }
  }
}


@media only screen and(min-width : $XXLDesktop) {
  .whatWeDoContainer {

    .descriptionContainer {

      .text {
        font-size: 26px;
        line-height: 34px;
        width: 800px;
      }
    }

    .seeMoreDesktopMobileSpider {
      position: absolute;
      left: 80%;

      .title {
        font-size: 26px;
        line-height: 36px;
      }

      .icon{
        font-size: 22px;
        width: 150px;
      }
    }
  }
}

@media only screen and(min-width : $UHD) {
  .whatWeDoContainer {
    .descriptionContainer {

      .title {
        font-size: 42px;
      }

      .text {
        font-size: 32px;
        line-height: 50px;
        width: 1000px;
      }
    }
  }
}


